<template>
  <div
    :id="`login-${theme}`"
  >
    <div class="header-bg p-relative">
      <!-- LOGO WEBSITE -->
      <div class="logo d-flex justify-center p-absolute pa-6 rounded-circle">
        <v-img
          v-if="websiteLogo"
          :src="baseImgUrl + websiteLogo"
          max-width="40"
        />
      </div>
      <img
        src="@/assets/image/login-header-template3.png"
        width="100%"
      >
    </div>

    <router-link
      to="/"
      class="p-absolute close-btn text-decoration-none"
    >
      <v-icon
        size="25"
        color="white"
      >
        mdi-close
      </v-icon>
    </router-link>

    <v-form
      ref="form"
      v-model="form.veridate"
    >
      <v-container>
        <v-row class="px-1">
          <v-col
            cols="12"
            class="py-1"
          >
            <!-- 帳號 -->
            <v-text-field
              id="acc"
              v-model="form.userName"
              :rules="[rules.requiredAcc]"
              :placeholder="$t('form.missAccount')"
              outlined
              color="secondary"
              autocapitalize="off"
              autocorrect="off"
              class="comment--text"
              :background-color="theme === 'light' ? 'white' : 'black'"
            >
              <div
                slot="prepend-inner"
                class="mt-1 px-2 comment--text"
              >
                {{ $t('subHeader.account') }}
              </div>
            </v-text-field>

            <!-- 密碼 -->
            <v-text-field
              id="pwd"
              v-model="form.password"
              :rules="[rules.requiredPwd]"
              :placeholder="$t('form.missPassword')"
              :type="form.showPassword ? 'text' : 'password'"
              outlined
              color="secondary"
              autocapitalize="off"
              autocorrect="off"
              class="text--text mt-2"
              :background-color="theme === 'light' ? 'white' : 'black'"
            >
              <div
                slot="prepend-inner"
                class="mt-1 px-2 text--text"
              >
                {{ $t('subHeader.password') }}
              </div>

              <!-- 目珠 -->
              <div
                slot="append"
                @click="form.showPassword = !form.showPassword"
              >
                <Icon
                  :data="form.showPassword ? eyeOpen : eyeClose"
                  width="24px"
                  height="24px"
                  class="text--text"
                />
              </div>
            </v-text-field>
          </v-col>

          <v-col
            id="remember"
            class="py-0 d-flex"
          >
            <div>
              <!-- 記住我 -->
              <v-checkbox
                v-model="form.rememberMe"
                dense
                class="mt-0 py-0"
              >
                <div
                  slot="label"
                  class="primary--text caption"
                >
                  {{ $t('form.rememberMe') }}
                </div>
              </v-checkbox>
            </div>

            <v-spacer />

            <!-- 忘記密碼 -->
            <div>
              <span
                class="link--text caption cursor-pointer"
                @click="forgotPassword"
              >
                {{ $t('subHeader.forgetPassword') }} ?
              </span>
            </div>
          </v-col>

          <v-col
            cols="12"
            class="pt-0"
          >
            <v-btn
              height="44px"
              :disabled="!form.veridate"
              block
              color="primary"
              @click="userLogin"
            >
              <span class="white--text subtitle-1">
                {{ $t('subHeader.login') }}
              </span>
            </v-btn>

            <!-- 第三方登入 -->
            <MediaLogin v-if="socialDisplay" />
          </v-col>

          <!-- 其它導向文字 -->
          <v-col class="pt-0 d-flex subtitle-2 justify-center">
            <!-- 註冊帳號 -->
            <router-link
              v-if="registerStatus"
              to="/login/register"
              class="text-decoration-none caption"
            >
              <span class="title--text">
                {{
                  $t('registerAndLogin.noAccount') + ' ' + $t('global.please')
                }}
              </span>
              {{ $t('registerAndLogin.switchRegister') }}
            </router-link>
          </v-col>
        </v-row>
      </v-container>
    </v-form>

    <Dialog
      :dialog="dialog.status"
      :width="280"
      :height="140"
    >
      <div
        slot="text"
        class="px-6"
      >
        {{
          $t('flashMessage.accountOrPwError')
        }}
      </div>

      <v-btn
        slot="btn"
        text
        color="primary"
        @click="dialog.status = !dialog.status"
      >
        {{ $t('global.tryAgain') }}
      </v-btn>
    </Dialog>

    <!-- forgot password dialog 忘記密碼 彈窗 -->
    <Dialog
      :width="280"
      :dialog="forgotPasswordDialog.status"
      @update-dialog="changeForgotDialog"
    >
      <div
        slot="text"
        class="px-6 subtitle-1 font-weight-bold"
      >
        <!--------------------------- 忘記密碼 (發送驗證碼) ----------------------->
        <div v-show="!forgotPasswordDialog.resetStatus">
          <p class="icon--text">
            {{ $t('subHeader.forgetPassword') }}
          </p>
          <v-form
            ref="verify1"
            v-model="forgotPasswordDialog.verify1"
            lazy-validation
          >
            <!-- 帳號 -->
            <span class="title--text caption font-weight-medium">
              {{ $t("subHeader.account") }}
            </span>
            <v-text-field
              v-model.trim="forgotPasswordDialog.form.account"
              dense
              :placeholder="$t('form.missAccount')"
              :rules="[rules.requiredAcc]"
              single-line
              outlined
            />

            <!-- 如果手機驗證 -->
            <div v-if="validateType === 'sms'">
              <!-- 手機驗証碼 -->
              <span class="title--text caption font-weight-medium">
                {{ $t('form.verifySMS') }}
              </span>
              <v-text-field
                v-model="forgotPasswordDialog.form.phone"
                dense
                forgot-password-dialog
                :placeholder="$t('form.missMobile')"
                :rules="[rules.requiredPhone]"
                single-line
                outlined
              />
            </div>

            <!-- 如果 email 驗證 -->
            <div v-if="validateType === 'email'">
              <!-- 信箱驗証碼 -->
              <span class="title--text caption font-weight-medium">
                {{ $t('form.verifyEmail') }}
              </span>
              <v-text-field
                v-model.trim="forgotPasswordDialog.form.email"
                dense
                :placeholder="$t('form.missEmail')"
                :rules="[rules.requiredEmail, rules.isEmail]"
                single-line
                outlined
                @keydown.enter="sendVerifyCode"
              />
            </div>
          </v-form>

          <!-- 發送驗證碼 -->
          <div class="text-right">
            <v-btn
              class="pa-0 caption"
              text
              color="primary"
              :disable="true"
              @click="sendVerifyCode"
            >
              {{ $t('myCenter.sendVerificationCode') }}
            </v-btn>
          </div>
        </div>

        <!--------------------------- 重設密碼 ----------------------->
        <div v-show="forgotPasswordDialog.resetStatus">
          <p class="icon--text">
            {{ $t('form.resetPassword') }}
          </p>

          <v-form
            ref="verify2"
            v-model="forgotPasswordDialog.verify2"
            lazy-validation
          >
            <!-- 驗證碼 -->
            <span class="title--text caption font-weight-medium">
              {{ $t('form.missCaptcha') }}
            </span>
            <v-container class="py-0">
              <v-row class="">
                <v-col
                  :cols="itemsWidth"
                  class="px-0"
                  :class="clientLang === 'ph' ? 'pb-0' : 'pr-1'"
                >
                  <v-text-field
                    v-model.trim="forgotPasswordDialog.form.verifyCode"
                    dense
                    :placeholder="$t('form.missVerifyCode')"
                    :rules="[rules.requiredVerifyCode]"
                    single-line
                    outlined
                  />
                </v-col>

                <v-col
                  :cols="itemsWidth"
                  class="px-0"
                  :class="clientLang === 'ph' ? 'pt-0' : 'pl-1'"
                >
                  <v-btn
                    block
                    outlined
                    color="primary"
                    class="px-0 caption"
                    :disabled="forgotPasswordDialog.resendVerifyCode.num < 60"
                    @click="sendVerifyCode"
                  >
                    <div v-show="forgotPasswordDialog.resendVerifyCode.num === 60">
                      {{ $t('global.resend') }}
                    </div>
                    <div v-show="forgotPasswordDialog.resendVerifyCode.num < 60">
                      {{ forgotPasswordDialog.resendVerifyCode.num + "s " + $t("global.retry") }}
                    </div>
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>

            <!-- 新登入密碼 -->
            <span class="title--text caption font-weight-medium">
              {{ $t("form.newPassword") }}
            </span>
            <v-text-field
              v-model.trim="forgotPasswordDialog.form.newPassword"
              type="password"
              dense
              :placeholder="$t('form.enterNewPassword')"
              :rules="[rules.requiredNewPassword, rules.newPasswordFormat]"
              single-line
              outlined
            />

            <div>
              <!-- 再次輸入新登入密碼 -->
              <span class="title--text caption font-weight-medium">
                {{ $t("form.newPasswordConfirm") }}
              </span>
              <v-text-field
                v-model.trim="forgotPasswordDialog.form.newPasswordConfirm"
                dense
                type="password"
                :placeholder="$t('form.enterNewPasswordAgain')"
                :rules="[rules.requiredNewPasswordConfirm, rules.passwordConfirm]"
                single-line
                outlined
              />
            </div>
          </v-form>

          <!-- 操作 -->
          <div class="text-right">
            <!-- 上一步 -->
            <v-btn
              class="pa-0 subtitle-2 subtitle--text mr-2"
              text
              @click="prevStep"
            >
              {{ $t('form.prev') }}
            </v-btn>

            <!-- 確認 -->
            <v-btn
              class="pa-0 subtitle-2"
              text
              color="primary"
              @click="resetPasswordSubmit"
            >
              {{ $t('global.confirmation') }}
            </v-btn>
          </div>
        </div>
      </div>
    </Dialog>

    <!-- 重設密碼失敗內容 -->
    <Dialog
      :dialog="forgotPasswordDialog.resetFailDialog.status"
      :width="280"
      :height="140"
    >
      <div
        slot="text"
        class="px-6"
      >
        {{ forgotPasswordDialog.resetFailDialog.content }}
      </div>

      <v-btn
        slot="btn"
        text
        color="primary"
        @click="forgotPasswordDialog.resetFailDialog.content = ''"
      >
        {{ $t('global.confirmation') }}
      </v-btn>
    </Dialog>
  </div>
</template>

<script>
import loginMixins from '../mixins/loginMixins'

export default {
  mixins: [loginMixins],
}
</script>

<style lang="scss" scoped>
.header-bg {
  background: linear-gradient(180deg, #C14B4B 0%, rgba(206, 171, 104, 0) 100%);
}
.logo {
  top: 25%;
  left: 15%;
  background: #2d2d2d;
}
.login-template3-dark {
  background: #161616 !important;
}
.login-template3-light {
  background: #EAEAEA !important;
}
.close-btn {
  top: 1rem;
  right: 1rem;
}
</style>
