<template>
  <div
    id="register"
    class="h-100dvh over-flow-y-scroll"
  >
    <v-form
      ref="form"
      v-model="form.veridate"
    >
      <v-container>
        <v-row class="px-1">
          <!-- 頭像 -->
          <v-col
            cols="12"
            class="mt-5 mb-2 py-0"
          >
            <div class="logo d-flex justify-center">
              <v-img
                v-if="websiteLogo"
                :src="baseImgUrl + websiteLogo"
                max-width="72"
              />
            </div>

            <h1
              v-if="!websiteLogo"
              class="text-center title--text"
            >
              {{ websiteName }}
            </h1>
          </v-col>

          <v-col
            cols="12"
            class="pb-0"
          >
            <!-- 輸入帳號 -->
            <v-text-field
              v-model="form.account"
              height="40px"
              :rules="[rules.require.acc, rules.length.acc, rules.format.acc]"
              autofocus
              :placeholder="$t('form.missAccount')"
              outlined
              autocapitalize="off"
              autocorrect="off"
              dense
            >
              <div
                slot="prepend-inner"
                class="mt-0"
              >
                <Icon
                  data="@icon/bottomNav/profile.svg"
                  width="24px"
                  height="24px"
                  class="primary--text"
                />
              </div>
            </v-text-field>
          </v-col>

          <v-col
            cols="12"
            class=" py-0"
          >
            <!-- 輸入密碼 -->
            <v-text-field
              v-model="form.password"
              height="40px"
              :rules="[rules.require.pwd]"
              :type="form.showPassword ? 'text' : 'password'"
              :append-icon="form.showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :placeholder="$t('form.missPassword')"
              outlined
              dense
              @click:append="form.showPassword = !form.showPassword"
              @focus="showPwdTipText"
              @blur="hiddenPwdTipText"
            >
              <!-- 鎖頭-->
              <div
                slot="prepend-inner"
                class="mt-0"
              >
                <Icon
                  data="@icon/password.svg"
                  width="24px"
                  height="24px"
                  class="primary--text"
                />
              </div>

              <!-- 眼睛-->
              <div
                slot="append"
                @click="form.showPassword = !form.showPassword"
              >
                <Icon
                  :data="form.showPassword ? eyeOpen : eyeClose"
                  width="24px"
                  height="24px"
                  class="primary--text"
                />
              </div>
            </v-text-field>
            <p
              class="pa-0 pl-2 caption"
              :class="{ 'd-none': !showPwdTipStatus }"
            >
              {{ $t('form.errorPwdFormat', { min: pwdRules.size_min, max: pwdRules.size_max }) }}
              <span
                v-for="(item, index) in pwdRules.requirement"
                :key="index"
              >
                {{ $t(`form.${item}`) }}
              </span>
            </p>
          </v-col>

          <v-col
            cols="12"
            class="py-0"
          >
            <!--  再次輸入密碼 -->
            <v-text-field
              v-model="form.password_confirmation"
              height="40px"
              :rules="[rules.require.confirmPwd, rules.verify.confirmPwd]"
              :type="form.showPasswordConfirmation ? 'text' : 'password'"
              :placeholder="$t('form.confirmPasswordAgain')"
              outlined
              dense
              @keydown.enter="userLogin"
            >
              <!-- 鎖頭-->
              <div
                slot="prepend-inner"
                class="mt-0"
              >
                <Icon
                  data="@icon/password_confirmation.svg"
                  width="24px"
                  height="24px"
                  class="primary--text"
                />
              </div>

              <!-- 眼睛-->
              <div
                slot="append"
                @click="
                  form.showPasswordConfirmation = !form.showPasswordConfirmation
                "
              >
                <Icon
                  :data="form.showPasswordConfirmation ? eyeOpen : eyeClose"
                  width="24px"
                  height="24px"
                  class="primary--text"
                />
              </div>
            </v-text-field>
          </v-col>

          <!-- 輸入推廣代碼 -->
          <v-col
            cols="12"
            class="py-0"
          >
            <v-text-field
              v-model="form.agentCode"
              height="40px"
              autofocus
              :placeholder="$t('form.missAgentCode')"
              outlined
              autocapitalize="off"
              autocorrect="off"
              dense
            >
              <div
                slot="prepend-inner"
                class="mt-0"
              >
                <Icon
                  data="@icon/recommend.svg"
                  width="24px"
                  height="24px"
                  class="primary--text"
                />
              </div>
            </v-text-field>
          </v-col>

          <v-col
            cols="12"
            class="py-0"
          >
            <!-- 輸入驗證碼 -->
            <v-text-field
              v-model="form.verifyCode"
              height="40px"
              :rules="[rules.require.verifyCode, rules.verify.verifyCode]"
              :placeholder="$t('form.missVerifyCode')"
              outlined
              inputmode="decimal"
              dense
            >
              <!-- 盾-->
              <div
                slot="prepend-inner"
                class="mt-0"
              >
                <Icon
                  data="@icon/verify.svg"
                  width="24px"
                  height="24px"
                  class="primary--text"
                />
              </div>

              <!-- 驗證碼 -->
              <div
                slot="append"
                class="identifyArea pa-0 ma-0 ma-0"
                @click="identifyReset(true)"
              >
                <Identify :identify-code="identifyCode" />
              </div>
            </v-text-field>

            <!-- 刷新說明 -->
            <span class="caption comment--text">
              {{ $t('form.missVerifyCodeFormat') }}
            </span>
          </v-col>

          <v-col class="py-0">
            <!-- 已經滿十八歲 -->
            <v-checkbox
              v-model="form.checkAge"
              :rules="[rules.require.age]"
              class="ma-0 chk-age"
            >
              <span
                slot="label"
                class="caption primary--text"
              >
                <!-- 開啟 規則彈窗 -->
                <span
                  class="ml-1 caption link--text text-decoration-underline cursor-pointer"
                  @click="rulesDialog.status = true"
                >
                  {{
                    $t('form.registerChecked', { name: websiteName })
                  }}
                </span>
              </span>
            </v-checkbox>
          </v-col>

          <v-col
            cols="12"
            class="py-0"
          >
            <!-- 註冊 btn -->
            <v-btn
              height="44"
              :disabled="!form.veridate"
              block
              color="primary"
              @click="userRegister(form)"
            >
              {{ $t('subHeader.register') }}
            </v-btn>

            <MediaLogin v-if="socialDisplay" />
          </v-col>
          <v-col>
            <!-- 先去逛逛 -->
            <div class="hang-out-btn w-100 text-center">
              <router-link
                to="/"
                class="text-decoration-none"
              >
                <v-btn
                  rounded
                  depressed
                  color="rgba(234, 234, 234, 0.12)"
                >
                  <span class="babyBlue--text subtitle-2">
                    {{ $t('registerAndLogin.hangOut') }}
                  </span>
                </v-btn>
              </router-link>
            </div>
          </v-col>

          <v-col
            cols="10"
            class="text-right"
          >
            <!-- 已經有帳號 -->
            <p class="caption">
              {{ $t('registerAndLogin.switchLogin') }}
              <router-link
                to="/login"
                class="text-decoration-none"
              >
                <span>{{ $t('subHeader.login') }}</span>
              </router-link>
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-form>

    <!-- 註冊彈窗 -->
    <Dialog
      :dialog="dialog.status"
      :width="280"
    >
      <span
        slot="text"
        class="px-6"
      >{{ dialog.text }}</span>

      <v-btn
        slot="btn"
        color="danger"
        text
        @click="dialog.status = !dialog.status"
      >
        {{ $t('global.cancel') }}
      </v-btn>

      <v-btn
        slot="btn"
        color="primary"
        text
        @click="dialog.status = !dialog.status"
      >
        {{ $t('global.tryAgain') }}
      </v-btn>
    </Dialog>

    <!-- 規則彈窗 -->
    <Dialog
      v-if="rulesDialog"
      :width="320"
      :dialog="rulesDialog.status"
    >
      <span
        slot="title"
        class="subtitle-1 title--text"
      >
        {{ rulesDialog.title }}
      </span>

      <div slot="text">
        <div class="px-6 rules__content">
          <ul class="pl-0 caption text--text">
            <li
              v-for="(item, index) in rulesDialog.content"
              :key="index"
              class="pb-2"
            >
              {{ item }}
            </li>
          </ul>
        </div>
      </div>

      <!-- 確認 -->
      <v-btn
        slot="btn"
        class="mb-2"
        text
        color="primary"
        @click="rulesDialogClose"
      >
        {{ $t('global.confirmation') }}
      </v-btn>
    </Dialog>

    <!-- 若已經登入 彈窗 -->
    <Dialog
      :dialog="isLogin"
      :height="140"
    >
      <div
        slot="text"
        class="px-6 text--text"
      >
        {{ $t('flashMessage.isLoginStatus') }}
      </div>

      <!-- 取消 -->
      <v-btn
        slot="btn"
        class="mb-2 text--text"
        text
        @click="$router.push({path: '/'})"
      >
        {{ $t('global.cancel') }}
      </v-btn>

      <!-- 確認 -->
      <v-btn
        slot="btn"
        class="mb-2"
        text
        color="primary"
        @click="userLogout"
      >
        {{ $t('global.confirmation') }}
      </v-btn>
    </Dialog>

    <!-- 註冊失敗 -->
    <Dialog
      :dialog="registerFailDialog.status"
      :width="280"
      :height="140"
    >
      <div
        slot="text"
        class="px-6"
      >
        {{
          registerFailDialog.text
        }}
      </div>

      <v-btn
        slot="btn"
        text
        color="primary"
        @click="registerFailDialog.status = !registerFailDialog.status"
      >
        {{ $t('global.tryAgain') }}
      </v-btn>
    </Dialog>
  </div>
</template>

<script>
import registerMixins from '../mixins/registerMixins'

export default {
  mixins: [registerMixins],
}
</script>

<style lang="scss" scoped>
ul {
  list-style: none;
}

.hang-out-btn {
  position: fixed;
  bottom: 22px;
  left: 0;
}
</style>
