import { mapActions, mapGetters, mapState } from 'vuex'
import { isValidateAccount as veridateAcc } from '@/utils/validate'
import { resStatus } from '@/utils/resUtils'
import isLocalDev from '@/utils/isLocalDev'
import { clickEventInterval } from '@/utils/event'
import eyeOpen from '@/assets/icons/eye-open.svg'
import eyeClose from '@/assets/icons/eye-close.svg'

import MediaLogin from '@/components/base/MediaLogin.vue'
import Identify from '@/components/verify/Identify.vue'
import Dialog from '@/components/dialog'

export default {
  components: {
    Identify,
    Dialog,
    MediaLogin,
  },

  data: () => ({
    dialog: {
      status: false,
      text: '',
    },

    rulesDialog: {
      status: false,
      title: '',
      content: [],
    },

    form: {
      account: '',
      password: '',
      password_confirmation: '',
      website: '',
      verifyCode: '',
      agentCode: '',

      veridate: false,
      checkAge: false,
      showPassword: false,
      showPasswordConfirmation: false,
    },

    identifyCode: '1234', // 验证码初始值
    identifyCodes: '1234567890', // 验证码的随机取值范围

    eyeOpen,
    eyeClose,

    // 執行過後，是否可以再打 api
    eventLaunch: {
      userRegister: null,
    },

    registerFailDialog: {
      status: false,
      text: null,
    },

    pwdTipText: false,
  }),

  computed: {
    ...mapState({
      websiteName: state => state.websiteData.site_params?.site_name,
      isLogin: state => state.user.isLogin,
      accountLengthMax: state => state.websiteData.account_size_max,
      settings: state => state.websiteData.settings,
      pwdRules: state => state.websiteData.password_rule,
    }),
    ...mapGetters(['baseImgUrl', 'websiteLogo', 'websiteName', 'socialDisplay', 'layout', 'theme']),

    /**
     * 驗証規則
     * @date 2021-06-01
     * @returns {boolean || string}
     */
    rules() {
      return {
        require: {
          acc: value => !!value || this.$t('form.missAccount'),
          pwd: value => !!value || this.$t('form.missPassword'),
          confirmPwd: value => !!value || this.$t('form.confirmPasswordAgain'),
          verifyCode: value => !!value || this.$t('form.missVerifyCode'),
          age: value => !!value || this.$t('form.missRegisterChecked'),
        },
        length: {
          acc: value =>
            (value.length >= 5 && value.length <= Number(this.accountLengthMax)) ||
            this.$t('form.errorAccountFormat', { max: Number(this.accountLengthMax) }),
          pwd: value => value.length > 5 || this.$t('form.errorPasswordFormat'),
        },
        format: {
          acc: value =>
            veridateAcc(value) || this.$t('form.errorAccountFormat', { max: Number(this.accountLengthMax) }),
        },
        verify: {
          confirmPwd: value =>
            value === this.form.password ||
            this.$t('form.errorComfirmPassword'),
          verifyCode: value =>
            value === this.identifyCode || this.$t('form.errorVerifyCode'),
        },
      }
    },

    // website 設定
    website() {
      // 是否本地開發
      const website = isLocalDev()
        ? process.env.VUE_APP_WEBSITE
        : location.host
          .replace('m.', '')
          // .replace('www.', '')
          .replace('v2.', '')
      return website
    },

    showPwdTipStatus() {
      return this.pwdTipText
    },
  },

  watch: {
    'registerFailDialog.status'(newStatus) {
      if (newStatus) return false
      this.registerFailDialog.text = null
    },
  },

  mounted() {
    this.identifyReset() // 動置驗證碼
    this.getTypeDoc('business') // 取得文件
    this.recommendCodeInSide(this.$route.query, this.form) // 如果有推薦碼

    // 初始化 延遲打 api
    Object.keys(this.eventLaunch)
      .forEach(index => {
        this.eventLaunch[index] = clickEventInterval(this.settings.click_interval)
      })
  },

  methods: {
    ...mapActions([
      'user_login',
      'user_register',
      'show_alert',
      'get_type_doc',
      'user_logout',
    ]),

    // 點擊輸入密碼
    showPwdTipText() {
      this.pwdTipText = true
    },
    hiddenPwdTipText() {
      this.pwdTipText = false
    },

    /**
     * 如果有推薦碼寫入 form
     * @date 2021-06-01
     * @param {string} {recommend} 來自 router query 的推薦碼
     * @param {any} recommend
     */
    recommendCodeInSide({ recommend }, form) {
      if (recommend) form.recommend = recommend
    },

    resetForm() {
      this.$refs.form.reset() // 表單重置
      Object.assign(this.form, this.$options.data().form)
    },

    /**
     * 註冊帳號 (成功)
     * @date 2021-05-27
     */
    registerSuccess() {
      this.show_alert({
        icon: 'done',
        text: this.$t('flashMessage.registerDone'),
      })

      this.identifyReset() // 驗證碼重置
      this.resetForm()
      this.$router.push({ path: '/login' })
    },

    /**
     * 註冊帳號 (失敗)
     * @date 2021-05-27
     * @param {any} errorText
     */
    registerFail(errorText) {
      this.registerFailDialog.text = errorText
      this.registerFailDialog.status = true

      this.identifyReset() // 驗證碼重置
      this.resetForm()
    },

    /**
     * 註冊帳號
     * @date 2021-05-27
     */
    async userRegister(form) {
      // 狀態未初始化 不可打
      if (!this.eventLaunch.userRegister.getStatus()) return false
      // 數秒後回複狀態
      this.eventLaunch.userRegister.setStatus(false)

      const { account, password, password_confirmation, website, recommend, agentCode } = form
      const userForm = {
        account,
        password,
        password_confirmation,
        website,
      }
      if (recommend) userForm.recommend = recommend
      if (agentCode) userForm.agent_code = agentCode

      const res = await this.user_register(userForm)
      resStatus(res, this.registerSuccess, this.registerFail)
    },

    // -------- 驗證碼 ----------------
    // 驗證碼重置 && 点击验证码刷新验证码
    identifyReset(type) {
      this.identifyCode = ''
      this.makeCode(this.identifyCodes, 4)
      if (type) this.$refs.form.validate()
    },
    // 生成一个随机整数  randomNum(0, 10) 0 到 10 的随机整数， 包含 0 和 10
    randomNum(min, max) {
      max = max + 1
      return Math.floor(Math.random() * (max - min) + min)
    },
    // 随机生成验证码字符串
    makeCode(data, len) {
      for (let i = 0; i < len; i++) {
        this.identifyCode += data[this.randomNum(0, data.length - 1)]
      }
    },

    /**
     * 依類別取得文件
     * @date 2021-05-11
     * @param {string} type 類別
     */
    async getTypeDoc(type) {
      const res = await this.get_type_doc(type)

      // 企業事務 文檔
      const rulesDetails = this.filterRulesDoc(res.docs.find(item => item.type === 'business'))

      this.rulesDialog.title = rulesDetails.name
      // 去除字符 轉換為斷落
      this.rulesDialog.content = rulesDetails.content.split('\r\n')
    },

    /**
     * 篩選指定文件 (規則與條款)
     * @date 2021-08-16
     * @param {any} docs
     * @returns {any}
     */
    filterRulesDoc({ docs }) {
      return docs[1].docs[0]
    },

    rulesDialogClose() {
      this.rulesDialog.status = false
      this.form.checkAge = true
    },

    async userLogout() {
      const res = await this.user_logout()
      resStatus(res, this.userLogoutSuccess, this.failDialog)
    },

    userLogoutSuccess(data) {
      this.userBaseInfo = []
      this.show_alert({
        icon: 'done',
        // text: data.message,
      })
    },

    failDialog(error) {
      this.show_alert({
        icon: 'fail',
        text: error,
      })
    },
  },
}
