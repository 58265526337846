<template>
  <div
    :id="`register-${layout}`"
  >
    <router-link
      to="/"
      class="p-absolute close-btn text-decoration-none"
    >
      <v-icon
        size="25"
        color="white"
      >
        mdi-close
      </v-icon>
    </router-link>

    <!-- WEB LOGO -->
    <div class="header-bg p-relative">
      <!-- LOGO WEBSITE -->
      <div
        class="logo d-flex justify-center p-absolute pa-6 rounded-circle"
      >
        <v-img
          v-if="websiteLogo"
          :src="baseImgUrl + websiteLogo"
          max-width="40"
        />
      </div>
      <img
        src="@/assets/image/login-header-template3.png"
        width="100%"
      >
    </div>

    <v-form
      ref="form"
      v-model="form.veridate"
    >
      <v-container>
        <v-row class="px-1">
          <v-col
            cols="12"
            class="pb-0"
          >
            <!-- 帳號 -->
            <v-text-field
              v-model="form.account"
              height="40px"
              :rules="[rules.require.acc, rules.length.acc, rules.format.acc]"
              :placeholder="$t('form.missAccount')"
              outlined
              autocapitalize="off"
              autocorrect="off"
              color="secondary"
              class="comment--text"
              :background-color="theme === 'light' ? 'white' : 'black'"
            >
              <div
                slot="prepend-inner"
                class="mt-1 px-2 comment--text"
              >
                {{ $t('subHeader.account') }}
              </div>
            </v-text-field>

            <!-- 密碼 -->
            <v-text-field
              v-model="form.password"
              class="mt-2"
              :rules="[rules.require.pwd]"
              :type="form.showPassword ? 'text' : 'password'"
              :append-icon="form.showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :placeholder="$t('form.missPassword')"
              :background-color="theme === 'light' ? 'white' : 'black'"
              outlined
              color="secondary"
              @click:append="form.showPassword = !form.showPassword"
              @focus="showPwdTipText"
              @blur="hiddenPwdTipText"
            >
              <div
                slot="prepend-inner"
                class="mt-1 px-2 text--text"
              >
                {{ $t('subHeader.password') }}
              </div>

              <!-- 目珠 -->
              <div
                slot="append"
                @click="form.showPassword = !form.showPassword"
              >
                <Icon
                  :data="form.showPassword ? eyeOpen : eyeClose"
                  width="24px"
                  height="24px"
                  class="text--text"
                />
              </div>
            </v-text-field>

            <!-- 密碼提示 -->
            <p
              class="pa-0 ma-0 mb-2 pl-3 caption"
              :class="{ 'd-none': !showPwdTipStatus }"
            >
              {{ $t('form.errorPwdFormat', { min: pwdRules.size_min, max: pwdRules.size_max }) }}
              <span
                v-for="(item, index) in pwdRules.requirement"
                :key="index"
              >
                {{ $t(`form.${item}`) }}
              </span>
            </p>

            <!-- 再次輸入密碼 -->
            <v-text-field
              v-model="form.password_confirmation"
              class="mt-2"
              color="secondary"
              :rules="[rules.require.confirmPwd, rules.verify.confirmPwd]"
              :type="form.showPasswordConfirmation ? 'text' : 'password'"
              :background-color="theme === 'light' ? 'white' : 'black'"
              :placeholder="$t('form.confirmPasswordAgain')"
              outlined
              @keydown.enter="userLogin"
            >
              <div
                slot="prepend-inner"
                class="mt-1 px-2 text--text"
              >
                {{ $t('subHeader.password') }}
              </div>

              <!-- 目珠 -->
              <div
                slot="append"
                @click="
                  form.showPasswordConfirmation = !form.showPasswordConfirmation
                "
              >
                <Icon
                  :data="form.showPassword ? eyeOpen : eyeClose"
                  width="24px"
                  height="24px"
                  class="text--text"
                />
              </div>
            </v-text-field>

            <!-- 輸入推廣代碼 -->
            <v-text-field
              v-model="form.agentCode"
              :placeholder="$t('form.missAgentCode')"
              outlined
              autocapitalize="off"
              :background-color="theme === 'light' ? 'white' : 'black'"
              autocorrect="off"
            >
              <div
                slot="prepend-inner"
                class="mt-1 px-2 text--text"
              >
                {{ $t('form.agentCode') }}
              </div>
            </v-text-field>

            <!-- 輸入驗證碼 -->
            <div class="p-relative">
              <v-text-field
                v-model="form.verifyCode"
                :rules="[rules.require.verifyCode, rules.verify.verifyCode]"
                :placeholder="$t('form.missVerifyCode')"
                outlined
                :background-color="theme === 'light' ? 'white' : 'black'"
                inputmode="decimal"
              >
                <!-- 盾-->
                <div
                  slot="prepend-inner"
                  class="mt-1 px-2 text--text"
                >
                  {{ $t('form.missCaptcha') }}
                </div>
              </v-text-field>

              <!-- 驗證碼 -->
              <div
                class="identifyArea"
                @click="identifyReset(true)"
              >
                <Identify :identify-code="identifyCode" />
              </div>
            </div>

            <!-- 刷新說明 -->
            <span class="caption comment--text">
              {{ $t('form.missVerifyCodeFormat') }}
            </span>

            <v-col class="py-0">
              <!-- 已經滿十八歲 -->
              <v-checkbox
                v-model="form.checkAge"
                :rules="[rules.require.age]"
                class="ma-0 chk-age"
              >
                <span
                  slot="label"
                  class="caption secondary--text"
                >
                  <span>
                    {{
                      $t('form.registerChecked', { name: websiteName }).substr(
                        0,
                        11
                      )
                    }}
                  </span>

                  <!-- 開啟 規則彈窗 -->
                  <span
                    class="ml-1 caption link--text text-decoration-underline cursor-pointer"
                    @click="rulesDialog.status = true"
                  >
                    {{
                      $t('form.registerChecked', { name: websiteName }).substr(11)
                    }}
                  </span>
                </span>
              </v-checkbox>
            </v-col>

            <v-col
              cols="12"
              class="py-0 px-0"
            >
              <!-- 註冊 btn -->
              <v-btn
                height="44"
                :disabled="!form.veridate"
                block
                color="primary"
                @click="userRegister(form)"
              >
                {{ $t('subHeader.register') }}
              </v-btn>

              <MediaLogin v-if="socialDisplay" />
            </v-col>

            <v-col
              cols="12"
              class="text-center"
            >
              <!-- 已經有帳號 -->
              <p class="caption">
                {{ $t('registerAndLogin.switchLogin') }}
                <router-link
                  to="/login"
                  class="text-decoration-none secondary--text"
                >
                  <span>{{ $t('subHeader.login') }}</span>
                </router-link>
              </p>
            </v-col>
          </v-col>
        </v-row>
      </v-container>
    </v-form>

    <!-- 註冊彈窗 -->
    <Dialog
      :dialog="dialog.status"
      :width="280"
    >
      <span
        slot="text"
        class="px-6"
      >{{ dialog.text }}</span>

      <v-btn
        slot="btn"
        color="danger"
        text
        @click="dialog.status = !dialog.status"
      >
        {{ $t('global.cancel') }}
      </v-btn>

      <v-btn
        slot="btn"
        color="primary"
        text
        @click="dialog.status = !dialog.status"
      >
        {{ $t('global.tryAgain') }}
      </v-btn>
    </Dialog>

    <!-- 規則彈窗 -->
    <Dialog
      v-if="rulesDialog"
      :width="320"
      :dialog="rulesDialog.status"
    >
      <span
        slot="title"
        class="subtitle-1 title--text"
      >
        {{ rulesDialog.title }}
      </span>

      <div slot="text">
        <div class="px-6 rules__content">
          <ul class="pl-0 caption text--text">
            <li
              v-for="(item, index) in rulesDialog.content"
              :key="index"
              class="pb-2"
            >
              {{ item }}
            </li>
          </ul>
        </div>
      </div>

      <!-- 確認 -->
      <v-btn
        slot="btn"
        class="mb-2"
        text
        color="primary"
        @click="rulesDialogClose"
      >
        {{ $t('global.confirmation') }}
      </v-btn>
    </Dialog>

    <!-- 若已經登入 彈窗 -->
    <Dialog
      :dialog="isLogin"
      :height="140"
    >
      <div
        slot="text"
        class="px-6 text--text"
      >
        {{ $t('flashMessage.isLoginStatus') }}
      </div>

      <!-- 取消 -->
      <v-btn
        slot="btn"
        class="mb-2 text--text"
        text
        @click="$router.push({path: '/'})"
      >
        {{ $t('global.cancel') }}
      </v-btn>

      <!-- 確認 -->
      <v-btn
        slot="btn"
        class="mb-2"
        text
        color="primary"
        @click="userLogout"
      >
        {{ $t('global.confirmation') }}
      </v-btn>
    </Dialog>

    <!-- 註冊失敗 -->
    <Dialog
      :dialog="registerFailDialog.status"
      :width="280"
      :height="140"
    >
      <div
        slot="text"
        class="px-6"
      >
        {{
          registerFailDialog.text
        }}
      </div>

      <v-btn
        slot="btn"
        text
        color="primary"
        @click="registerFailDialog.status = !registerFailDialog.status"
      >
        {{ $t('global.tryAgain') }}
      </v-btn>
    </Dialog>
  </div>
</template>

<script>
import registerMixins from '../mixins/registerMixins'

export default {
  mixins: [registerMixins],
}
</script>

<style lang="scss" scoped>
ul {
  list-style: none;
}

.hang-out-btn {
  position: fixed;
  bottom: 22px;
  left: 0;
}
.header-bg {
  background: linear-gradient(180deg, #C14B4B 0%, rgba(206, 171, 104, 0) 100%);
}
.logo {
  top: 25%;
  left: 15%;
  background: #2d2d2d;
}
.identifyArea {
  position: absolute;
  top: 15px;
  right: 10px;
}

.close-btn {
  top: 1rem;
  right: 1rem;
  z-index: 1;
}
</style>
